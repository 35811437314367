import React, { Component }from "react";
import Row from "reactstrap/lib/Row";
import  { Col } from "reactstrap/lib";
import { Button } from 'reactstrap';
import ApiEndpoints from "../../../constants/api_endpoints";
import store from "../../../store";
import { setError, setLoader } from "../../../actions/action-creators";
import { groupArrayOnKey } from "../../../actions";
import { Icon } from "@material-ui/core";
import moment from "moment";


class UserDocuments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            documents : [],
            imagesData: {}
        }
    }

    componentDidMount(){
        this.fetchDocuments();
    }

   
      fetchDocuments = async () => {
        store.dispatch(setLoader(true))
        try{
            var body = { user_id : this.props.user_id, type: ["PAN", "AADHAAR"] }
            let documentResp = await ApiEndpoints.UserProfile.fetchDocument(body)
            let documents = documentResp.data.data;
            documents = documents.sort((a, b) => a.type > b.type);
            this.setState({ documents })
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
      }

      
      downloadFileByID = async (fileId) => {
        store.dispatch(setLoader(true))
        try{
            var fileBody = {file_id : fileId}
            let urlResp = await ApiEndpoints.Files.getFileID(fileBody);
            let fileUrl = urlResp.data.data[0] ? urlResp.data.data[0].url : null;

            let imagesData = {[fileId.toString()]: fileUrl};
            this.setState({ imagesData });
        }
        catch(e){
            store.dispatch(setError({show: true, message: e.response ? e.response.data.message : e.toString()}))
        }
        store.dispatch(setLoader(false))
      }

      
    render() {
        let { documents, imagesData } = this.state;
        return (
            <>
               <Row>
                    <Col xs={12}>
                        { documents.length ? <>
                            <div className="my-3">
                                {/*
                               
                                {
                                    documentByType["AADHAAR"] ? 
                                    documentByType["AADHAAR"].map(doc => 
                                    <>
                                        <span className="d-block">Number: ******{(doc["AADHAAR"].number || "").slice(6)}</span>
                                       
                                        <br />
                                        {imagesData[documentByType["AADHAAR"].file_id] && <img src={imagesData[documentByType["AADHAAR"].file_id]} style={{width: "45%", marginRight: "5%"}} alt="Aadhaar Front" />}
                                        {imagesData[documentByType["AADHAAR"].file_id2] && <img src={imagesData[documentByType["AADHAAR"].file_id2]} style={{width: "45%"}} alt="Aadhaar Front" />}
                                    </>
                                    )
                                    : 
                                    <span className="small">No Aadhaar card added</span>
                                }

                               */}
                               {
                                documents.map(doc => {
                                    return (
                                        <div>
                                            <h6 className="mb-3">{doc.type} Card</h6>
                                            <div className="bg-light shadow-sm p-2 m-2">
                                                <Row>
                                                    <Col>
                                                        <span className="d-block">Number: {(doc.number || "").slice(0, 3)}****{(doc.number || "").slice(6)}</span>
                                                    </Col>
                                                    <Col className="text-right">
                                                        {doc.file_id && <Button onClick={() => this.downloadFileByID(doc.file_id)} type="button" color="link" className="mr-1" style={{ fontSize: "12px"}}>Front <Icon style={{fontSize: 12}} className="align-middle">visibility</Icon></Button>}
                                                        {doc.file_id2 && <Button onClick={() => this.downloadFileByID(doc.file_id2)} type="button"  color="link" className=" mr-1" style={{fontSize: "12px"}}>Back <Icon  style={{fontSize: 12}} className="align-middle">visibility</Icon></Button>}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={8}>
                                                        <span className="small d-block">Created: {moment(doc.created_on).utcOffset("+05:30").format("Do MMM'YY HH:mm")}</span>
                                                        {!!doc.modified_on && doc.created_on === doc.modified_on && <span className="small d-block">Updated: {moment(doc.modified_on).utcOffset("+05:30").format("Do MMM'YY HH:mm")}</span>}
                                                    </Col>
                                                    <Col className="text-rejected">
                                                        <span className={"font-weight-bold small "+ (doc.status ===1 ? "text-success" : doc.status === 2 ? "text-danger" : "text-secondary")}>{doc.status ===1 ? "Verified" : doc.status === 2 ? "Rejected" : ""}</span>
                                                    </Col>
                                                </Row>
                                                {!!doc.description && <i className="small text-danger d-block mt-2">{doc.description}</i>}
                                            </div>
                                        </div>
                                    )
                                })
                               }
                            </div>
                           
                        </>
                        : <em className="m-4 text-secondary d-block">No Aadhaar/PAN details added</em> }
                    </Col>
                </Row>
            </>
           
        );
    }
}
export default UserDocuments;
